import { FC, Fragment, useEffect, useRef, useState } from 'react'
import styles from './Pod4NoPhone.module.scss'
import { Pod4NoPhoneProps } from './types'
import cx from 'classnames'
import { AlarmSlide, CoolSlide, ElevationSlide, WarmSlide } from 'components/Pod4NoPhone/slides'
import { Button } from 'components/Phantom/Button'
import { VideoGif } from 'components/basic/VideoGif'
import { observer } from 'mobx-react'
import { useRootStore } from 'components/_hooks/useRootStore'

const SLIDE_DURATION = 3 * 1000

const getCurrentSlideIndex = (time: number, numSlides: number) => {
	return Math.floor(time / SLIDE_DURATION) % numSlides
}

export const TabbedMedia = ({ slides }) => {
	const [selected, setSelected] = useState(0)
	const currentAnimTime = useRef(0)

	useEffect(() => {
		let lastTime = 0
		let animationId = 0
		let lastSlideIndex = 0

		const animate = (time: number) => {
			const delta = time - lastTime
			lastTime = time
			currentAnimTime.current += delta

			// Reset the animation time if we've reached the end
			if (currentAnimTime.current >= slides.length * SLIDE_DURATION) {
				currentAnimTime.current = 0
			}

			const currentSlide = getCurrentSlideIndex(currentAnimTime.current, slides.length)

			// If we detect a slide transition, update the selected state
			if (currentSlide !== lastSlideIndex) {
				setSelected(currentSlide)
				lastSlideIndex = currentSlide
				const video: HTMLVideoElement = document.getElementById(`no-phone-video-${currentSlide}`) as HTMLVideoElement

				if (video) {
					video.currentTime = 0
					video.play()
				}
			}

			for (let i = 0; i < slides.length; i++) {
				const progressElement = document.getElementById(`no-phone-progress-${i}`)
				if (!progressElement) {
					continue
				}

				if (i !== currentSlide) {
					progressElement.style.opacity = '0'
					continue
				}

				const slideStartTime = i * SLIDE_DURATION
				const slideProgress = (currentAnimTime.current - slideStartTime) / SLIDE_DURATION

				progressElement.style.opacity = '1'
				progressElement.style.width = `${Math.min(slideProgress, 1) * 100}%`
			}

			animationId = requestAnimationFrame(animate)
		}

		animationId = requestAnimationFrame(animate)

		return () => {
			cancelAnimationFrame(animationId)
		}
	}, [])

	useEffect(() => {
		currentAnimTime.current = selected * SLIDE_DURATION
	}, [selected])

	const texts = ['Tap to cool down', 'Tap to warm up', 'Tap to snooze', 'Tap to elevate']
	const videos = [
		'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2-tap2x.mp4',
		'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/3-tap2x.mp4',
		'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2-tap2x.mp4',
		'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/4-tap2x.mp4',
	]

	return (
		<div className={styles.tabContainer}>
			<div className={styles.media_container}>
				{slides.map((slide, index) => (
					<div
						key={index}
						className={cx(styles.slide, {
							[styles.slide_selected]: selected === index,
						})}
					>
						{slide}
					</div>
				))}
				<div className={styles.video_container}>
					{videos.map((video, index) => (
						<VideoGif
							key={`video-${texts[index]}`}
							className={styles.media}
							src={video}
							style={{
								opacity: selected === index ? 1 : 0,
							}}
							id={`no-phone-video-${index}`}
						/>
					))}
				</div>
			</div>
			<div className={styles.tabs}>
				{texts.map((text, index) => (
					<Fragment key={text}>
						<Button.Empty
							id={`no-phone-tab-${index}`}
							onClick={() => setSelected(index)}
						>
							<span
								className={cx(styles.tab)}
								style={{
									opacity: selected === index ? 1 : 0.35,
								}}
							>
								<span
									id={`no-phone-progress-${index}`}
									className={styles.tab_progress}
								/>
								{text}
							</span>
						</Button.Empty>
					</Fragment>
				))}
			</div>
		</div>
	)
}

export const NoPhoneConnected = observer(() => {
	const { metricRegion } = useRootStore().settingsStore
	return <Pod4NoPhone metric={metricRegion} />
})

export const Pod4NoPhone: FC<Pod4NoPhoneProps> = (props) => {
	const { metric } = props

	const slides = [<CoolSlide metric={metric} />, <WarmSlide metric={metric} />, <AlarmSlide />, <ElevationSlide />]
	return (
		<div className={styles.container}>
			<div className={styles.pitch}>
				<div className={styles.pill}>New</div>
				<div className={styles.title}>
					<div>Say hello to tap to control</div>
				</div>
				<div className={styles.description}>
					An innovative and intuitive feature that uses sensing technology to control your Pod experience. Finally, you can leave your phone outside the bedroom.
				</div>
			</div>
			<TabbedMedia slides={slides} />
		</div>
	)
}
